import getLocale from '@/helpers/getLocale';

export const txtTitle = {
  id: `Kehadiran Bapak/Ibu/Saudara/i akan menjadi kehormatan besar bagi kami dan keluarga. Mohon konfirmasi kehadiran Anda melalui formulir reservasi di bawah ini:`,
  en: `Your presence shall be a great honor for us and our families. Please confirm your attendance through the reservation form below:`,
};

export const Title = {
  id: `Konfirmasi Kehadiran`,
  en: `Confirm your Attendance`,
};

export const txtForm = {
  name: getLocale('Nama', 'Name'),
  nameHelper: getLocale(
    '*) RSVP bersifat personal sehingga hanya bisa mengkonfirmasikan 1 nama tamu undangan saja',
    '*) Guest can only confirm 1 name due to personalized RSVP',
  ),
  hp: getLocale('Nomor HP', 'Phone Number'),
  address: getLocale('Alamat', 'Address'),
  willYoutAttend: getLocale('Apakah anda akan hadir?', 'Will you attend the wedding?'),
  willAttend: getLocale('Ya, saya akan hadir', 'Yes, I  will gladly attend'),
  noAttend: getLocale('Tidak, saya tidak bisa hadir', `No, I can't attend the wedding`),
  willYouBringPartner: getLocale(
    'Berapa jumlah tamu yang akan hadir?',
    `How many guests will be attending?`,
  ),
  withPartner: getLocale('Ya, saya akan membawa 1 partner', `Yes, I will bring 1 partner here`),
  withPartnerCustom: (total) =>
    getLocale(`Ya, saya akan membawa ${total} partner`, `Yes, I will bring ${total} partner here`),
  noPartner: getLocale('Tidak, saya akan hadir sendiri', `No, I will be by myself`),
  submit: getLocale('Kirim Konfirmasi', `Submit Confirmation`),
  required: getLocale('Wajib diisi', 'This field required'),
  success: getLocale('Sukses!', 'Success!'),
  msgSuccess: getLocale(
    'Formulir reservasi anda berhasil dikirim. Terima Kasih!',
    'Your response already submitted. Thank you!',
  ),
  msgError: getLocale(
    'Ada kesalahan ketika mengirimkan reservasi anda. Silahkan coba beberapa saat lagi.',
    'Something wrong happened. Please try again later or refresh your browser!',
  ),
  invalidEmail: getLocale('Email tidak valid', 'Invalid email address'),
  invalidPhone: getLocale('Nomor HP tidak valid', 'Invalid phone number'),
};
